import { GetStaticPaths, GetStaticProps } from 'next';
import { getLocalePaths } from '@i18n';

import { METRIC_ENUM } from 'src/metric/config';
import Crypto from '@components/pages/crypto/page';

const CryptoPage: React.FC = () => {
    return <Crypto widget={{ hideSocials: true, needPhone: true }} />;
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
    return { props: { locale: params?.locale, metric: METRIC_ENUM.cryptoMetric } };
};

export const getStaticPaths: GetStaticPaths = async () => {
    return { paths: getLocalePaths(), fallback: false };
};

export default CryptoPage;
